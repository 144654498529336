<template>
    <div style="position: relative;width: 1400px;margin: 0 auto;">
          <div class="navLeft" @mouseenter="handleLeftEnter" @mouseleave="handleLeftLeave" v-if="isShowFirstNav">
              <!-- all -->
              <div :class="'left_item '+(goodsTypeTreeCurrent.cls_no==='all'?active_left_item:'') " @click="$router.push('/web/allGoods')" @mouseenter="handleAllEnter" style="padding-top: 18px;">
                <div class="left_item_text">{{ $t("All Merchandise") }}</div>
                <div class="left_item_icon right-arrow"></div>
              </div>
              <!-- 1级 -->
              <div :class="'left_item '+(value1.cls_no===goodsTypeTreeCurrent.cls_no?active_left_item:'')" @click="clickToUrl(value1,'2')" v-if="value1.has_goods_num>0" v-for="(value1,index1) in goodsTypeTree" @mouseenter="handleLeftItemEnter(value1)">
                <div class="left_item_text">{{ lang === "zh-CN" ? value1.cls_cn : value1.cls_name }}</div>
                <div class="left_item_icon right-arrow"></div>
              </div>
          </div>
          <div class="navRight" :style="{display:NavRightDisplay}" @mouseenter="handleRightEnter(goodsTypeTreeCurrent)" @mouseleave="handleRightLeave">
              <div class="right_item_title">{{ lang === "zh-CN" ? goodsTypeTreeCurrent.cls_cn : goodsTypeTreeCurrent.cls_name }}</div>
              <!-- 2级 -->
              <template v-for="(value2,index2) in goodsTypeTreeCurrent.sub">
                <div class="right_item" v-if="value2.has_goods_num>0" >
                    <div class="right_item_first" @click="clickToUrl(value2,'2')">
                      <div class="right_item_nav">{{ lang === "zh-CN" ? value2.cls_cn : value2.cls_name }}</div>
                      <div class="right_item_icon right-arrow"></div>
                    </div>
                    <!-- 3级 -->
                      <div class="right_item_second">
                        <template v-if="value2.sub">
                            <div class="right_item_text" @click="clickToUrl(value3,'2')" v-for="(value3,index3) in value2.sub">{{ lang === "zh-CN" ? value3.cls_cn : value3.cls_name }}</div>
                        </template>
                      </div>
                </div>
              </template>
          </div>
        </div>
</template>

<script>
import { getTreeType } from "@/api/goods/goodsType.js";


export default {
  data() {
    return {
        lang: "",
        goodsTypeTree:{},
        goodsTypeTreeCurrent:{},
        NavRightDisplay:"none",
        active_left_item:"",
        isShowFirstNav:true,
        // 延时设置菜单
        setNavNone:null,
    };
  },
  async created() {
    this.getLang();
    this.getType();//获取菜单
    this.setFisrtNav()
  },
  watch: {
    // 监听路由变化
    $route(to, from) {
      // console.log(this.$route)
      this.setFisrtNav()
    },
    '$route.query'(){
      if (this.$route.path.indexOf("web/allGoods")>=0) {
        this.$router.go(0)
      }
    }
  },
  methods: {
    // 点菜单跳转到分类的页面
    clickToUrl(typeValue,hideBtype){
      let {
					href
				} = this.$router.resolve({
					path: "/web/allGoods",
					query: {
            cls_no: typeValue.cls_no,
            hideBtype:hideBtype
					},
				});
				window.location.href= href
    },
    // 设置首个菜单
    setFisrtNav(){
      if (this.$route.path.indexOf("web/index")<0) {
        this.isShowFirstNav = false
      } else {
        this.isShowFirstNav = true
      }
    },
    // 设置首个菜单显示并取消定时器
    setFisrtNavShow(){
      if(this.$route.path.indexOf("web/index")<0){
        this.isShowFirstNav = true
        if(this.setNavNone){
          clearTimeout(this.setNavNone)
          this.setNavNone = null
        }
      }
    },
    // 设置首个菜单隐藏并添加定时器
    setFisrtNavNone(){
      this.setNavNone=setTimeout(() => {
        if(this.$route.path.indexOf("web/index")<0){
          this.isShowFirstNav = false
        }
      }, 100);
    },
    setActiveItem(){
      this.NavRightDisplay = "block"
      this.active_left_item = "active_left_item"
      this.setFisrtNavShow()
    },
    cancelActiveItem(){
      this.NavRightDisplay = "none"
      this.active_left_item = ""
      this.setFisrtNavNone()
    },   
    handleAllEnter(){
      this.goodsTypeTreeCurrent = {
      cls_no :'all'
      }
      this.NavRightDisplay = "none"
      this.active_left_item = "active_left_item"
      this.setFisrtNavShow()
    },
    handleRightEnter(){
      this.setActiveItem()
    },
    handleRightLeave(){
      this.goodsTypeTreeCurrent = {}
      this.cancelActiveItem()
    },
     // 菜单的鼠标移入事件
     handleLeftItemEnter(parentMenu){
      this.goodsTypeTreeCurrent = parentMenu
      this.setActiveItem()
    },
    handleLeftEnter(){
      this.setFisrtNavShow()
    },
    // 菜单的鼠标移出事件
    handleLeftLeave(){
      this.cancelActiveItem()
    },
    //获取类型
    async getType() {
      const rec= await getTreeType();
      this.goodsTypeTree=rec.data.data;
    },
    // 获取当前的语言环境
    getLang() {
            // if (this.$i18n.locale == "zh-CN") {
            // 	this.lang = this.$i18n.locale
            // } else {
            // }
            this.lang = this.$i18n.locale;
        },
    


  },
};
</script>
<style lang="scss" scoped>
  /* *******************第一个菜单样式**********开始****** */
  .navLeft{
    border: none !important;
    width: 235px !important;
    height: 500px;
    background-color: #e7f8fb !important;
    position: relative;
    z-index: 100;
  }
  .left_item{
    width: 100%;
    display: flex;
    font-size: 16px;
    padding: 10px 10px;
    text-align: center;
  }
  .left_item:hover{
    cursor: pointer;
  }
  .left_item_text{
    width: 85%;
    text-align: left;
    color:rgb(132, 131, 131);
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
  }
  .left_item_icon{
    width: 15%;
    color:rgb(132, 131, 131);
    font-weight: bold;
  }

  // 左箭头
  // .left-arrow {
  //   border: 1px solid rgb(132, 131, 131);
  //   width: 11px;
  //   height: 11px;
  //   border-left-color: transparent;
  //   border-top-color: transparent;
  //   transform: rotate(135deg);//左箭头
  //   margin: 3px;
  // }
  // 右箭头
  .right-arrow {
    border: 1px solid rgb(132, 131, 131);
    width: 11px;
    height: 11px;
    border-left-color: transparent;
    border-top-color: transparent;
    transform: rotate( -45deg);//右箭头
    margin: 3px;
  }  
  .navRight{
    width: 1165px;
    height: 500px;
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    left: 235px;
    top: 0;
    text-align: left;
  }
  .right_item_title{
    color: #0090a1;
    font-weight: bold;
    padding: 5px 20px;
    font-size: 18px;
  }
  .right_item{
    display: flex;
    // padding: 7px 0;
    padding: 5px 0;
  }
  .right_item_first{
    width: 230px;
    display: flex;
  }
  .right_item_nav{
    width: 90%;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
  }
  .right_item_second{
    width: 935px;
    // padding: 0 10px;
    color: rgb(132, 131, 131);
  }
  .right_item_text{
    width: auto;
    display: inline-block;
    padding: 0 18px;
    // padding-bottom: 15px;
    padding-bottom: 10px;
  }
  .right_item_text:hover{
    color: #0090a1;
    cursor: pointer;
  }
  .active_left_item>.left_item_text{
    color: #0090a1;
  }
  
  /* *******************第一个菜单样式************结束***** */
</style>