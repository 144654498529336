<template>
  <div class="header">
    <div class="header_top">
      <div class="header_top_left" style="cursor: hand">
        <div>
          <el-dropdown>
            <span
              class="el-dropdown-link"
              style="width: 150px; cursor: pointer; font-size: 18px">
              Hello, {{ this.username }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  type="info"
                  plain
                  style="margin-top: 5px; width: 120px; font-size: 18px"
                  @click="$router.push('/login')"
                  >{{ $t("Logout") }}</el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="header_top_right">
        <p v-if="userInfo.pid === 0 || userInfo.show_price === 1">
          <p style="font-size: 18px" >{{ $t("Balance") }}：</p>
          <p style="font-size: 18px">
            {{ $t("Price symbol") }}{{ userInfo.price }}
          </p>
        </p>
        <div style="margin-left: 30px">
          <el-dropdown>
            <span class="el-dropdown-link" style="cursor: pointer; font-size: 18px">{{ $t("Management Center") }}<i class="el-icon-arrow-down el-icon--right"></i></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button
                  type="info"
                  plain
                  style="width: 200px; font-size: 18px"
                  @click="$router.push('/web/orderCenter/personalCenter')"
                  >{{ $t("Personal Center") }}
                </el-button>
              </el-dropdown-item>
              <div  v-if="userInfo.pid === 0 || userInfo.show_price === 1">
              <el-dropdown-item>
                <el-button
                  type="info"
                  plain
                  style="margin-top: 5px; width: 200px; font-size: 18px"
                  @click="$router.push('/web/orderCenter/bookingOrderList')"
                  >{{ $t("Presell Order") }}
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="info"
                  plain
                  style="margin-top: 5px; width: 200px; font-size: 18px"
                  @click="$router.push('/web/orderCenter/salesGoodsList')"
                  >{{ $t("Order Center") }}
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="info"
                  plain
                  style="margin-top: 5px; width: 200px; font-size: 18px"
                  @click="$router.push('/web/orderCenter/applyAfterSalesList')"
                >
                  {{ $t("After-Sales Service") }}</el-button
                >
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="info"
                  plain
                  style="margin-top: 5px; width: 200px; font-size: 18px"
                  @click="$router.push('/web/orderCenter/salesTurnover')"
                  >{{ $t("Store Business") }}
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button
                  type="info"
                  plain
                  style="margin-top: 5px; width: 200px; font-size: 18px"
                  @click="$router.push('/web/orderCenter/downloadList')"
                  >{{ $t("Resource Download") }}
                </el-button>
              </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div style="margin-left: 30px">
          <el-dropdown @command="handleChangeLan">
            <span
              class="el-dropdown-link"
              style="cursor: pointer; font-size: 18px">
              {{ $t("Language") }}：{{ $t("languageLabel") }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="zh-CN" style="font-size: 18px">中文</el-dropdown-item>
              <el-dropdown-item command="en" style="font-size: 18px">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <div class="header_inner">
      <div class="header_inner_content">
        <div class="header_inner_content_left">
          <img
            src="~@/assets/images/LOGO.png"
            @click="$router.push('/web/index')"
            style="margin: 10px 0 0 80px; height: 60px"
          />
        </div>
        <div class="header_inner_content_middle">
          <div class="inputDeep">
            <el-input v-model="input" style="width: 600px" @keyup.native.enter="searchName()"></el-input>
          </div>
          <div class="search">
            <el-button
              icon="el-icon-search"
              type="btn_sty"
              circle
              @click="searchName()"
            ></el-button>
          </div>
        </div>
        <div class="header_inner_content_right" @click="$router.push('/web/shoppingCart')">
          <div class="shopping_cart" style="cursor: pointer">
            <img src="~@/assets/images/icon_shopping_cart.png" style="margin: 5px 3px 6px 10px" alt=""/>
            <span style="margin: 10.5px 0 6px; color: #333333">
              {{ $t("Quantity") }}:
            </span>
            <span style="margin: 10.5px 0 6px; color: #ed4014">
              {{ CountList }}
            </span>
            <div v-if="userInfo.pid === 0 || userInfo.show_price === 1">
              <span style="margin: 10.5px 0 6px 10px; color: #333333">
                {{ $t("Amount") }}:
              </span>
              <span style="margin: 10.5px 0 6px; color: #ed4014">
                {{ $t("Price symbol") }}{{ TotalPrice }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="header_bottom">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo navMenu"
        mode="horizontal"
        @select="handleSelect"
        active-text-color="#218da0"
      >
        <el-menu-item class="navTitle1" index="0" @mouseenter.native="handleMouseEnter" @mouseleave.native="handleMouseLeave">{{ $t("Catalogue") }}</el-menu-item>

        <el-menu-item :class="{navMenuLi}" index="1" @click="$router.push('/web')" style="color: #212121 !important;border-bottom-color: transparent !important;">{{$t("Home") }}</el-menu-item>
        <!-- <el-menu-item index="2" @click="$router.push('/web/allGoods')">{{ $t("All Merchandise") }}</el-menu-item> -->
        <el-menu-item :class="{navMenuLi}" index="4" @click="$router.push('/web/newProduct')">{{ $t("New Arrivals") }}</el-menu-item>
        <el-menu-item :class="{navMenuLi}" index="11" @click="$router.push('/web/hotProducts')">{{ $t("Hot-sale Products") }}</el-menu-item>
        <el-menu-item :class="{navMenuLi}" index="8"  @click="$router.push('/web/presellProduct')" style="color: #ff9900;">{{$t('Pre-sale Event')}}</el-menu-item>
        <el-menu-item :class="{navMenuLi}" index="5" @click="$router.push('/web/marketing')">{{ $t("Special Offers") }}</el-menu-item>
        <el-menu-item :class="{navMenuLi}" index="7" @click="$router.push('/web/ipSeries')">{{$t("Series") }}</el-menu-item>
        <el-menu-item :class="{navMenuLi}" index="10" @click="$router.push('/web/display')">{{ $t("Display Resource") }}</el-menu-item>
        <el-menu-item :class="{navMenuLi}" index="3" @click="$router.push('/web/warehouseProducts')">{{ $t("China Warehouse") }}</el-menu-item>
      </el-menu>
        <!--  第一个菜单  -->
        <template >
          <FirstNav ref="FirstNav"></FirstNav>
        </template>
        <!-- ----------- -->
    </div>
  </div>
</template>
<script>
import { SsCart } from "@/api/carts/carts.js";
import FirstNav from "@/views/web/layout/Header/first_nav_child.vue";


export default {
  components: {
    FirstNav,
  },
  data() {
    return {
      input: "",
      CountList: 0,
      TotalPrice: 0,
      activeIndex: "0",
      userInfo: {},
      language: "",
      navMenuLi:"",
    };
  },
  created() {
    this.getLang();
    this.setNavMenuClass()
    this.loadUsername();
    this.initCartData();
    const user = window.sessionStorage.getItem("webUser");
    const userArr = JSON.parse(user);
    this.userInfo = userArr["user_info"];
    if (this.userInfo.pid !== 0 || this.userInfo.show_price !== 1)this.userInfo.price = 0
  },

  methods: {
    //获取语言
    getLang() {
      this.language=this.$i18n.locale;
    },
    // 设置菜单的样式
    setNavMenuClass(){
      if (this.language==="zh-CN") {
        this.navMenuLi = "navMenuLi"
      } else{
        this.navMenuLi = ""
      }
    },

    // 鼠标移入
    handleMouseEnter(e){
      this.$refs.FirstNav.setFisrtNavShow()
    },
    // 鼠标移出
    handleMouseLeave(e){
      this.$refs.FirstNav.setFisrtNavNone()
    },
    //初始化购物车
    initCart() {
      this.initCartData();
    },
    //获取用户名
    loadUsername() {
      const user = window.sessionStorage.getItem("webUser");
      const userArr = JSON.parse(user);
      this.userInfo = userArr.user_info;
      this.username = userArr.user_info.username;
    },
    handleChangeLan(e) {
      // this.$i18n.locale = e;
      window.sessionStorage.setItem("lg", e);
      this.$router.go(0);
    },
    //头部搜索
    searchName() {
      let searchType = 2
      if (+this.input > 0) searchType = 1
      this.$axios.post("/w1/goods/searchlog",{search_type:searchType,search_content: this.input})
      this.$router.push({
        path: "/web/allGoods",
        query: {
          name: this.input,
        },
      });
      //location.href = "/#/web/allGoods?name="+this.input
    },
    //初始化购物车数量和价格
    initCartData() {
      let webUser = window.sessionStorage.getItem("webUser");
      let webUserJson = JSON.parse(webUser);
      if (webUserJson.user_info.id > 0 ) {
        if (webUserJson.user_info.pid === 0 || webUserJson.user_info.show_price === 1) {
          SsCart(webUserJson.user_info.id)
            .then((res) => {
              let resData = res.data.data;
              this.CountList = resData.CountList;
              this.TotalPrice = resData.TotalPrice;
            })
            .catch(() => {
              this.$message.error("Failed to get shopping cart data");
            });
        }
      } else {
        this.$message.error("userinfo ERROR");
      }
    },
    handleSelect(key, keyPath) {},


  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
}

.header_top {
  width: 100%;
  height: 40px;
  font-size: 15px;
  line-height: 15px;
  background-color: #f5f8fa;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &_left {
    width: 150px;
    padding: 12.5px 0px;
    color: #515a6e;
    margin-left: 50px;
    // display: flex;
    // align-items: flex-start;
    // justify-content: flex-start;
  }

  &_right {
    width: 900px;
    color: #515a6e;
    padding: 12.5px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 150px;
  }
}

.el-dropdown-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header_inner {
  width: 100%;
  height: 80px;
  background-color: #ffffff;

  &_content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 80px;
    margin: 0 auto;

    &_left {
      width: 230px;
      height: 80px;
    }

    &_middle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 660px;
      height: 44px;
      border: 2px solid #238da5;
      border-radius: 30px;
      position: relative;
    }

    &_right {
      width: 290px;
      height: 44px;
      border: 2px solid #238da5;
      border-radius: 30px;
      margin-right: 50px;
    }
  }
}

.shopping_cart {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.search {
  width: 64px;
  height: 43px;
  position: absolute;
  right: -1px;
  top: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #238da5;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;

  img {
    width: 30px;
    height: 30px;
  }
}

.header_bottom {
  width: 100%;
  // display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin: 20px 0;
}

.el-menu-item {
  // font-size 20px;
  font-size: 18px;
  color: #212121;
  font-weight: bold;
}
</style>
<style>
.inputDeep .el-input__inner {
  border: 0;
}

.el-button--btn_sty {
  background: #238da5;
  color: white;
  border-color: #238da5;
  font-size: 18px;
}



/* *******************第一个菜单样式**********开始****** */

.navMenu{
  width: 1400px;
  position: relative;
  margin: 0 auto;
}
.navMenu>li{
  text-align: center;
}
.navMenu>li:last-child{
  padding-right: 0;
}
.navMenuLi{
  width: 145px;
}

.el-menu--horizontal > .el-menu-item{
  height: 50px;
  line-height: 50px;
  padding: 0 14px;
}
.header_bottom{
  margin-bottom: 0 !important;
}
.navTitle1{
  width: 235px;
  border: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;
  background-color:#0090a1 !important;
  color: #fff !important;
  padding: 0 !important;
}
.navTitle1:hover{
  cursor: auto;
}

/* *******************第一个菜单样式************结束***** */





</style>
